import { put, take } from "redux-saga/effects"
import * as API from "../api"
import { ITicketInQueue, setTicketsInQueueAction, ITicketInDesk } from "../type"
import { Ticket } from "../../../../models/ticket"
import { Desk } from "../../../../models/desk"
import { User } from "../../../../models/user"
import {PUSH_ICOMING} from "../../shared/constants";

export default function* () {
  while (true) {
    const tickets = yield API.getTicketsInQueue()
    const ticketsInQueue: ITicketInQueue[] = tickets.tickets_in_queue.map((td: any) => ({
      ticket: Ticket.buildFromRaw(td, Ticket)
    }))
    const ticketsOnDesk: ITicketInDesk[] = tickets.tickets_on_desks.map((td: any) => ({
      ticket: Ticket.buildFromRaw(td.ticket, Ticket),
      desk: Desk.buildFromRaw(td.desk, Desk),
      user: User.buildFromRaw(td.user, User)
    }))
    yield put(setTicketsInQueueAction(ticketsInQueue, ticketsOnDesk))
    yield take(PUSH_ICOMING)
  }
}