import React, {useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../../store'
import {
  finishTicketAction,
  finishAndStopTicketAction,
  forwardTicketAction,
  selectAnotherLineAction,
  currentTicketRecallAction, statusChangingAction,
} from "../store";
import { useTranslation } from 'react-i18next'
import {Typography, Button, MenuItem, TextField, Box, Grid} from '@material-ui/core'
import Popover from './popover'
import Template from './step_template'
import History from '../../../../models/ticket/components/history'
import HistoryVisit from './history_visit'
import {TClientData, TExpertData} from '../../../../models/ticket/type'
import ExpertDataEditor from '../../../../models/ticket/components/expert_data'
import OtherLineDialog from './other_line_dialog'
import InfoSessionDialog from '../info_session_dialog'
import FreezeDialog from '../freeze_dialog'
import { EntityId } from '../../../../features/entity/type'
import Header from '../../../../models/ticket/components/ticket_header'
import {RemoteSyncTicketContainer, Ticket} from "../../../../models/ticket";
import PhoneTickets from "../../ticket/phone_ticket";
import { cancelTicketAction } from '../../../../models/ticket/saga'
import CommentComponent from '../../../../models/comment/comment_component'

export default () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [dialogOtherLineOpen, setDialogOtherLineOpen] = useState(false)
  const [dialogInfoSessionOpen, setDialogInfoSessionOpen] = useState(false)
  const [dialogFreezeOpen, setDialogFreezeOpen] = useState(false)
  const [selectedTicketForSession, setSelectedTicketForSession] = useState()

  const { tickets, validation } = useSelector((state: AppState) => ({
    tickets: Object.keys(state.tickets.entities).map(k => state.tickets.entities[k] as Ticket),
    validation: state.validation.page === "expert" ? state.validation : null
  }))

  const currentRole = useSelector((state: AppState) => state.policy.currentRole)
  const ticket = useSelector((state: AppState) => state.expertPage.currentTicket)

  const ticketUrlWithPhone = '/tickets/hub_history?hub_id=' + ticket?.hub_id + '&phone=' + ticket?.client_data.phone

  const [expertData, setExpertData] = useState<TExpertData>(ticket?.expert_data || {})
  const [clientData, setClientData] = useState<TClientData>(ticket?.client_data ? { ...ticket.client_data } : {})

  const finishTicketClick = () => dispatch(finishTicketAction(expertData, clientData))
  const finishAndStopTicketClick = () => dispatch(finishAndStopTicketAction(expertData, clientData))
  const onForwarding = (lineId: EntityId) => {
    dispatch(forwardTicketAction(expertData, clientData))
    dispatch(selectAnotherLineAction(lineId))
  }

  const onChangeClientData = (prop: string, value: any) => {
    const newClientData = { ...clientData }
    newClientData[prop] = value
    setClientData(newClientData)
  }

  const onShowInfo = (ticket: Ticket) => {
    setSelectedTicketForSession(ticket)
    setDialogInfoSessionOpen(true)
  }

  const validateClassName = "ep-failed-validate-field"

  const bottom = <>
    <Popover test-dataid="ep-popover-button">
      <>
    {/*
        <MenuItem
          qa-tag="ep-recall-button"
          onClick={() => {
            //dispatch( statusChangingAction('WAIT_CLIENT'))
            dispatch(currentTicketRecallAction(ticket))
          }}>
          {t('Recall')}</MenuItem>
      */}
        <MenuItem
          qa-tag="ep-finish-end-interupt-button"
          onClick={finishAndStopTicketClick}>
          {t('Finish and interupt')}</MenuItem>
        {(currentRole != 'expert') && (
          <MenuItem
          qa-tag="ep-cancel-button"
          onClick={()=>dispatch(cancelTicketAction(ticket?.id || ""))}>
          {t('cancel ticket')}</MenuItem>
        )}
        <MenuItem
          qa-tag="ep-freeze-button"
          onClick={()=> setDialogFreezeOpen(true)}>
          {t('Freeze client')}</MenuItem>
        <MenuItem qa-tag="ep-forward-button" onClick={()=> setDialogOtherLineOpen(true)}>{t('Forward to other queue')}</MenuItem>
      </>
    </Popover>
    <OtherLineDialog onForwardTo={onForwarding} ticketId={ticket?.id} open={dialogOtherLineOpen} setOpen={setDialogOtherLineOpen} />
    <InfoSessionDialog ticket={selectedTicketForSession} open={dialogInfoSessionOpen} setOpen={setDialogInfoSessionOpen} />
    <FreezeDialog ticketId={ticket?.id || ''} open={dialogFreezeOpen} setOpen={setDialogFreezeOpen} />
    <Button
      variant="contained"
      color="primary"
      style={{ marginLeft: 20, width: 156 }}
      className="primary-button"
      qa-tag="ep-next-client-button"
      onClick={finishTicketClick}>{t('Next client')}</Button>
  </>
  return <>
    <RemoteSyncTicketContainer customUrl={ticketUrlWithPhone} />
    <Template
      header={<Header status={""} ticket={ticket}/>}
      bottom={bottom}>
      <Grid
        container
        direction="row"
        justify="space-between">
        <Grid xs={9}>
          <Box display="flex" flexDirection="column" marginTop={2}>
            <Box style={{marginLeft: "-5px"}}>
              {Object.keys(ticket?.client_data || {}).map((prop, index) =>
                prop !== "phone"
                  ? <TextField
                    key={index}
                    label={prop}
                    value={clientData[prop]}
                    onChange={(event) => onChangeClientData(prop, event.target.value)}
                    className={`ep-client-data cl-field ${validation?.errorsClientData[prop] && !clientData[prop] ? validateClassName : ""}`}/>
                  : <PhoneTickets
                    value={clientData[prop]}
                    phone_code={ticket?.phone_code || ""}
                    className={validation?.errorsClientData[prop] && (clientData[prop] as string)?.trim()?.length < 12 ? validateClassName : ""}
                    key={index}
                    label={t(prop)}
                    onChange={(value) => onChangeClientData(prop, value)}
                    minPhoneNumber={2} />)}
            </Box>
            <Box marginTop={2} style={{marginLeft: "-5px"}}>
              {ticket ?
                <ExpertDataEditor ticket={ticket}
                  expertData={expertData}
                  setExpertData={setExpertData}
                  lang={t('current lang')}
                  validation={validation}/>
                : null}
            </Box>
            <Grid
              container
              direction="row"
              justify="flex-start">
              <Box>
                <Box>
                  <Typography variant="h5" style={{ fontSize: 18, fontWeight: "normal", marginTop: 30 }}>
                    {t('visit_history')}
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="row">
                  <HistoryVisit history={tickets || []} showInfo={onShowInfo} />
                </Box>
              </Box>
              <Box style={{marginLeft: '40px'}}>
                <Box>
                  <Typography variant="h5" style={{ fontSize: 18, fontWeight: "normal", marginTop: 30 }}>
                    {t('Session data')}
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="row">
                  <History history={ticket?.history || []} />
                </Box>
              </Box>
            </Grid>
          </Box>
        </Grid>
        {
          ticket
            ? <Box marginTop={2} marginBottom={2}><CommentComponent ticket={ticket} typePanel="client" /></Box>
            : null
        }
      </Grid>
    </Template>
  </>
}
