import React from 'react'
import { Divider } from '@material-ui/core'
import { PolicyVisible } from '../../../features/policy'
import { MenuItem } from './menu_item'
import {
  getDashboardIcon,
  getExpertIcon, getLanguageIcon,
  getNewTicketIcon,
  getSettingsIcon,
  getStatisticsIcon
} from '../../../assets/icons'
import { useTranslation } from 'react-i18next'

export const MainMenu = () => {
  const { t } = useTranslation()
  return <>
    <Divider className="menu-divider" />
    <PolicyVisible path="/admin_dashboard" roles={["admin"]}>
      <MenuItem href={`/admin_dashboard`} title={t("dashboard")} className="menu-item">
        {getDashboardIcon()}
      </MenuItem>
    </PolicyVisible>
    <PolicyVisible path="/expert_dashboard" roles={["expert", "manager"]}>
      <MenuItem href={`/expert_dashboard/today`} title={t("dashboard")} className="menu-item">
        {getDashboardIcon()}
      </MenuItem>
    </PolicyVisible>
    <PolicyVisible path="/expert" roles={["admin", "manager", "expert"]}>
      <MenuItem href={`/expert`} title={t("service")} className="menu-item">
        {getExpertIcon()}
      </MenuItem>
    </PolicyVisible>
    <PolicyVisible path="/statistics" roles={["admin"]}>
      <MenuItem href={`/statistics`} title={t("statistics")} className="menu-item">
        {getStatisticsIcon()}
      </MenuItem>
    </PolicyVisible>
    <PolicyVisible path="/new_ticket" roles={["admin", "manager"]}>
      <MenuItem href={`/new_ticket`} title={t("new ticket")} className="menu-item">
        {getNewTicketIcon()}
      </MenuItem>
    </PolicyVisible>
    <PolicyVisible path="/admin" roles={["admin", "manager"]}>
      <MenuItem href={`/admin`} title={t("admin")} className="menu-item">
        {getSettingsIcon() }
      </MenuItem>
    </PolicyVisible>
    <PolicyVisible path="/language" roles={["admin", "manager", "expert"]}>
      <MenuItem href={`/language`} title={t("lang")} className="menu-item">
        {getLanguageIcon()}
      </MenuItem>
    </PolicyVisible>
  </>
}
