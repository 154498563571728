import React from 'react'
import { PolicySwitch, PolicyRoute } from "../features/policy"
import { ExpertPage } from './pages/expert'
import { AdminPage } from './pages/admin'
import AuthPage from './pages/auth'
import Logout from './pages/authentication/components/logout'
import { expertPageBp } from './pages/expert/saga'
import { adminSaga } from './pages/admin'
import HomePage from './pages/home'
import { sagaBuilder } from '../features/policy/saga'
import { deviceSaga, DevicePage } from './pages/device'
import { authSaga } from './pages/authentication/reducers/sagas'
import { StatisticsPage } from './pages/statistics'
import { statisticsSaga } from './pages/statistics/saga'
import { AdminDashBoardPage, ExpertDashBoardPage } from './pages/dashboard'
import { updatePushAfterLogin } from './pages/shared/helpRequestSaga'
import { NewTicketPage, newTicketSaga } from './pages/ticket'
import { LanguagePage } from './pages/languge'
import commentSaga from '../models/comment/saga'

export const policy = <PolicySwitch>
  <PolicyRoute path="/user" pageSaga={[authSaga]}>
    <AuthPage />
  </PolicyRoute>
  <PolicyRoute path="/device" pageSaga={[deviceSaga]}>
    <DevicePage />
  </PolicyRoute>
  <PolicyRoute path="/admin" roles={["admin", "manager"]} pageSaga={[authSaga, adminSaga, updatePushAfterLogin]}>
    <AdminPage />
  </PolicyRoute>
  <PolicyRoute path="/expert" roles={["manager", "admin", "expert"]} pageSaga={[authSaga, expertPageBp, updatePushAfterLogin, commentSaga]}>
    <ExpertPage />
  </PolicyRoute>
  <PolicyRoute path="/statistics" roles={["manager", "admin", "expert"]} pageSaga={[authSaga, statisticsSaga, updatePushAfterLogin]}>
    <StatisticsPage />
  </PolicyRoute>
  <PolicyRoute path="/new_ticket" roles={["manager", "admin"]} pageSaga={[authSaga, updatePushAfterLogin, newTicketSaga, commentSaga]}>
    <NewTicketPage />
  </PolicyRoute>
  <PolicyRoute path="/admin_dashboard" roles={["admin"]} pageSaga={[authSaga, updatePushAfterLogin]}>
    <AdminDashBoardPage />
  </PolicyRoute>
  <PolicyRoute path="/expert_dashboard" roles={["expert", "manager"]} pageSaga={[authSaga, updatePushAfterLogin]}>
    <ExpertDashBoardPage />
  </PolicyRoute>
  <PolicyRoute path="/logout" pageSaga={[]} >
    <Logout />
  </PolicyRoute>
  <PolicyRoute path="/language" roles={["manager", "admin", "expert"]} pageSaga={[authSaga, updatePushAfterLogin]}>
    <LanguagePage />
  </PolicyRoute>
  <PolicyRoute path="/">
    <HomePage />
  </PolicyRoute>
</PolicySwitch>

export const rootPolicySaga = sagaBuilder(policy)
