export default {
    translations: {

        "current lang":
            "ru",

        "too_many_pre_entries_per_day":
            "Слишком много записей в один день",

        "Repeated input of phone!":
            "Повторный ввод номера!",

        "webpush_problem":
            "Похоже, что в Вашем браузере не работают web push. Это может привести к некорректной работе.",

        "Input other number":
            "Ввести другой номер",

        "Cancel":
            "Отмена",

        "Start":
            "Начать работу",

        "Start queue handling, when you will be ready":
            "Как будете готовы, начните обработку очереди",

        "Start queue handling":
            "Начать обработку",

        "Alarm button":
            "Тревожная кнопка",

        "Change desk":
            "Сменить стол",

        "Please, choose your table":
            "Пожалуйста, выберите ваш стол",

        "Before the handling":
            "Перед началом обслуживания",

        "Expert work desk":
            "Рабочий стол эксперта",

        "Next":
            "Продолжить",

        "End work":
            "Завершить работу",

        "Wait for client assignment":
            "Назначение клиента",

        "Client incoming":
            "Клиент пришёл",

        "Wait for client":
            "Идет ожидание клиента",

        "No client":
            "Клиента нет",

        "Recall":
            "Вызвать ещё раз",

        "Next client":
            "Следующий",

        "Finish and interupt":
            "Завершить и прерваться",

        "Forward to other queue":
            "Переадресовать",

        "Return to queue":
            "Вернуть в очередь",

        "Freeze client":
            "Заморозить тикет",

        "Session data":
            "Данные сессии",

        "visit_history":
            "История посещений",

        "created_by_terminal":
            "Создано в терминале",

        "enqueued":
            "В очереди",

        "started":
            "Обработка",

        "assigned":
            "Назначен",

        "finished":
            "Завершён",

        "cancelled":
            "Отменён",

        "forwarded":
            "Переадресован",

        "hold":
            "Заморожен",

        "holded":
            "Заморожен",

        "recalled":
            "Вызван повторно",

        "returned":
            "Возвращён",

        "checked_in":
            "Проверен",

        "Please, select the line":
            "Выберите очередь",

        "search":
            "Поиск",

        "display_name":
            "Название",

        "lang":
            "Язык",

        "phone_code":
            "Телефонный код страны",

        "add":
            "Добавить",

        "assignment":
            "Назначить",

        "configurate":
            "Изменить",

        "time_slots":
            "Время работы",

        "devices":
            "Устройства",

        "address":
            "Адрес",

        "city_id":
            "Город",

        "role_id":
            "Роль",

        "name":
            "Пользователь",

        "from":
            "с",

        "till":
            "до",

        "hour_short":
            "ч",

        "min_short":
            "мин",

        "human_short":
            "чел",

        "Period":
            "Период",

        "Capacity":
            "Клиентов",

        "Time slots":
            "Время работы хаба",

        "kind":
            "Вид",

        "state":
            "Статус",

        "activation_code":
            "Код активации",

        "Fields":
            "Поля",

        "default":
            "По умолчание",

        "enabled":
            "Включено",

        "Admin":
            "Администратор",

        "electric_queue":
            "Электронная очередь",

        "ticket":
            "Тикет",

        "desk":
            "Окно",

        "waiting":
            "Ожидание",

        "expert":
            "Эксперт",

        "Expert":
            "Эксперт",

        "city":
            "Город",

        "hub":
            "Хаб",

        "experts":
            "Эксперты",

        "to":
            "до",

        "Tickets by hubs":
            "История посещений по хабам",

        "Tickets by experts":
            "История посещений по экспертам",

        "user_id":
            "Пользователь",

        "hub_id":
            "Хаб",

        "total":
            "Всего",

        "line_id":
            "Очередь",

        "avatar":
            "Аватарка",

        "email":
            "email",

        "password":
            "Пароль",

        "ticket_no":
            "Тикет",

        "line":
            "Очередь",

        "Work session by experts":
            "Контроль рабочего времени по экспертам",

        "Work session by hubs":
            "Контроль рабочего времени по хабам",

        "date":
            "Дата",

        "closed_at":
            "Завершение",

        "Expert load":
            "Загрузка экспертов",

        "interaction_time":
            "Обслуживание",

        "block/reset":
            "Блок/Сброс",

        "created_at":
            "Создано",

        "started_at":
            "Начало",

        "finished_at":
            "Завершено",

        "wait_time":
            "Ожидание",

        "phone":
            "Телефон",

        "tickets":
            "Тикеты",

        "dashboard":
            "Дашборд",

        "tickets done":
            "Посетителей обслужено",

        "waiting time":
            "Время ожидания в очереди",
        "ticket topic":
            "Тематика обращения",

        "clients":
            "Посетители",

        "expert work":
            "Работа экспертов",

        "work_time":
            "Время работы",

        "working":
            "В работе",

        "created":
            "Создан",

        "tickets in work":
            "Посетителей обслуживается",

        "tickets wait":
            "Посетителей ожидает",

        "manager in work":
            "Экспертов занято",

        "today":
            "Сегодня",

        "yesterday":
            "Вчера",

        "week":
            "За неделю",

        "month":
            "За месяц",

        "client":
            "Посетитель",

        "save":
            "Сохранить",

        "cancel ticket":
            "Отменить тикет",

        "help request":
            "нуждается в помощи",

        "need_help_on_desk":
            "Нужна помощь на столе",

        "Manager":
            "Менеджер",

        "back":
            "Назад",

        "towns":
            "Города",

        "lines":
            "Очереди",

        "desks":
            "Окна",

        "hubs":
            "Хабы",

        "users":
            "Пользователи",

        "templates":
            "Шаблоны",

        "ticket was changed":
            "Тикет был изменён",

        "high":
            "Наивысший",

        "elevated":
            "Повышенный",

        "normal":
            "Нормальный",

        "loyalty level":
            "Уровень лояльности",

        "create ticket":
            "Создание тикета",

        "create":
            "Создать",

        "tags":
            "Теги",

        "tag_ids":
            "Теги",

        "tag":
            "Тег",

        "service":
            "Сервис",

        "statistics":
            "Статистика",

        "new ticket":
            "Добавить тикет",

        "admin":
            "Настройки",

        "tv_display_name":
            "Имя на TV",

        "logout":
            "Выйти",

        "settings":
            "Настройки",

        "electronic queue enrollment":
            "Запись в электронную очередь",

        "to get in line":
            "Встать в очередь",

        "sign up at another time":
            "Записаться в другое время",

        "type phone":
            "Введите номер телефона",

        "type first name":
            "Введите имя",

        "type second name":
            "Введите фамилию",

        "next":
            "Далее",

        "hello!":
            "Здравствуйте!",

        "you have joined the queue":
            "Вы записались в очередь, номер вашего тикета:",

        "take the ticket":
            "Возьмите тикет и дождитесь, пока вас позовут.",

        "thank you!":
            "Спасибо!",

        "you have successfully signed up for the driver center":
            "Вы успешно записались в центр водителей",

        "date time":
            "Дата и время",

        "signup":
            "Зерегистрироваться",

        "russian":
            "Русский",

        "russian_eda":
            "Русский (еда)",

        "english":
            "Английский",

        "armenian":
            "Армянский",

        "Please, choose your language":
            "Пожалуйста, выберите язык:",

        "Detail":
            "Детали",

        "by hubs":
            "По хабам",

        "visit history":
            "История посещений",

        "work time conotrol":
            "Контроль рабочего времени",

        "by experts":
            "По экспертам",

        "expert report":
            "Загрузка экспертов",

        "select all":
            "Выбрать всё",

        "select nothing":
            "Очистить",

        "wihout tag":
            "Без тега",

        "first_name":
            "Имя",

        "last_name":
            "Фамилия",

        "created_at_date":
            "Создан",

        "session":
            "Сессия",

        "service history":
            "История обслуживания",

        "data":
            "Данные",

        "driver":
            "Посетитель",

        "look":
            "Посмотреть",

        "origin":
            "Начало",

        "end":
            "Конец",

        "select_date_and_time":
            "Выберите дату и время",

        "start period":
            "Начало периода",

        "end period":
            "Конец периода",

        "served":
            "Обслужено",

        "did not come":
            "Не пришло",

        "total tickets":
            "Всего тикетов",

        "service time":
            "Время обслуживания",

        "max service time":
            "Максимальное время обслуживания",

        "average service time":
            "Среднее время обслуживания",

        "min service time":
            "Минимальное время обслуживания",

        "h":
            "ч",

        "min":
            "мин",

        "sec":
            "сек",

        "work time":
            "Время работы",

        "name client":
            "Посетитель",

        "took to work":
            "Взял в работу",

        "completed":
            "Завершил",

        "history change":
            "История изменений",

        "changes saved successfully":
            "Изменения успешно сохранены",

        "reset date":
            "Сбросить дату",

        "input data in ticket":
            "Введите данные для создания нового талона",

        "load yet":
            "Загрузить ещё",

        "close session":
            "Закрыть сессию",

        "success close session":
            "Сессия успешно закрыта",

        "failed close session":
            "Сессия не закрыта",

        "tickets created by experts":
            "Тикеты, созданные для пользователей",

        "error required fields":
            "Обязательные поля пустые или заполнены не верно!",

        "by users":
            "По пользователям",

        "select ticket":
            "Выбрать тикет",

        "select":
            "Выбрать",

        "mode":
            "Тип",

        "link":
            "Ссылка",

        "waiting time per day":
            "Время в ожидании в сутки",

        "ticket processing time per day":
            "Время обработки тикетов в сутки",

        "total working hours per day":
            "Общее время работы в сутки",

        "auto":
            "Автоматическая очередь",

        "manual":
            "Ручная очередь",

        "cancelled_by_system":
            "Отменён системой",

        "need help":
            "Нужна помощь!",

        "apply":
            "Принять",

        "comment":
            "Комментарий",

        "comment created":
            "Комментарий создан!",

        "comment edited":
            "Комментарий изменён!",

        "comment deleted":
            "Комментарий удалён!",

        "comments":
            "Комментарии",

        "change":
            "Изменить",

        "delete":
            "Удалить",

        "sms":
            "Настройка смс",

        "message":
            "Сообщение",

        "sender":
            "Отправитель",

        "delay in minutes":
            "Задержка (в минутах)",

        "registered":
            "Зарегистрирован",

        "enter time in minutes":
            "Введите время в минутах",

        "terminal":
            "Терминал",

        "select line":
            "Выберите очередь",

        "time wait in line":
            "Время ожидания в очереди",

        "min time wait":
            "Минимальное время ожидания",

        "avg time wait":
            "Среднее время ожидания",

        "max time wait":
            "Максимальное время ожидания",

        "time process ticket in line":
            "Время обработки заявки в очереди",

        "min time process":
            "Минимальное время обработки",

        "avg time process":
            "Среднее время обработки",

        "max time process":
            "Максимальное время обработки",

        "action":
            "Действие",

        "print":
            "Распечатать",

        "hello":
            "Здравствуйте",

        "you signed up":
            "Вы записались в очередь:",

        "your ticket number":
            "Ваш номер в очереди:",

        "take the coupon and wait for you to be called":
            "Возьмите талон и дождитесь, пока вас позовут.",

        "city_with_nubs_destroy":
            "Нельзя удалить город, если он привязан к хабам",

        "hub_with_ticket_destroy":
            "Нельзя удалить хаб, если у него есть незавершенные тикеты",

        "hub_with_work_sessions_destroy":
            "Нельзя удалить хаб, если у него есть открытые сессии",

        "line_with_tickets_destroy":
            "Нельзя удалить очередь, если у в ней находятся незавершенные тикеты",

        "desk_with_ticket_destroy":
            "Нельзя удалить окно, если у в нем обслуживается тикет",

        "user_with_work_session_destroy":
            "Нельзя удалить пользователя, если у него есть открытые сессии",

        "record_not_unique":
            "Нельзя добавить больше одного комментария за действие",

        "oops an error occured":
            "Упс, произошла ошибка!",

        "link in sms":
            "Ссылка в смс",

        "tv":
            "Телевизор",

        "repeat call":
            "Повторить вызов можно через:",

        "platinum_gold":
            "Платина / Золото",

        "silver_bronze":
            "Серебро / Бронза",

        "without_status":
            "Без статуса",

        "status_loyalty":
            "Ваш статус лояльности",

        "invite":
            "Приглашаем"
    }
}
