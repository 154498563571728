import {call, fork, put, race, select, take, takeEvery} from 'redux-saga/effects'
import {AppState} from '../../store'
import {
  CLIENT_HENDLING,
  CLIENT_INCOMING,
  DESK_CHANGE_ACTION,
  DESK_SELECT_ACTION,
  DESK_SELECTION,
  END_WORK,
  FINISH_AND_STOP_TICKET,
  FINISH_TICKET,
  FIRST_SCREEN,
  HELP_REQUEST,
  ISelectAnotherLine,
  MISS_CLIENT,
  RECALL,
  CURRENT_TICKET_RECALL,
  SELECT_ANOTHER_LINE, SELECT_ANOTHER_LINE_MANUAL_LINE,
  START_WORK,
  START_WORK_SESSION,
  STOP_WORK_SESSION,
  WAIT_CLIENT,
  WAIT_TICKET,
  WAIT_WORK_SESSION
} from './type'
import {PUSH_ICOMING} from "../shared/constants"
import {
  selectDeskAction,
  setCurrentTicketAction,
  setWorkSessionAction,
  statusChangingAction,
  stopWorkSessionAction
} from './store'
import {Ticket} from '../../../models/ticket'
import {Desk, loadDesk} from '../../../models/desk'
import {fetchM as fetchMSource} from '../shared/fetchM'
import {TClientData, TExpertData} from '../../../models/ticket/type'
import {Line, loadLine} from '../../../models/line'
import {loadHub} from '../../../models/hub'
import {IFetchAction} from '../authentication/reducers/sagas'
import {
  successMessageAction,
  warningMessageAction
} from '../shared/common_store'
import {EntityId} from '../../../features/entity/type'
import {
  CANCEL_TICKET_ACTION,
  getCurrentTicket,
  getTicketsForLine,
  loadHistory, startTicket,
  TICKETS_HISTORY_LOAD
} from '../../../models/ticket/saga'
import {validateClientData, validateExpertData} from "../../../shared/validation/saga";
import {clearValidationFieldsAction, validationFieldsAction} from "../../../shared/validation/validation_store";
import i18next from "i18next";
import {buildActionTypeName} from "../../../features/entity/store/actions";
import {getLinesForForward} from "../../../models/line/saga";
import {SUCCESS_ACCESS_FORWARD_LINES} from "../../../models/line/line_additional_store";

function* fetchM(url: RequestInfo, options: RequestInit,
  callbackAction?: string,
  prefix?: string | undefined) {
  const response = yield call(fetchMSource, url, options, callbackAction, prefix)
  if (response instanceof Response) {
    throw response
  }
  return response
}

const FETCH_ACTION = "FETCH_ACTION"
export const getDesk = ({ expertPage }: AppState) => expertPage.selected_desk
export const getExpertData = ({ expertPage }: AppState) => expertPage.expert_data
export const getClientData = ({ expertPage }: AppState) => expertPage.client_data
export const selectDesc = ({ desks }: AppState, id: string) => desks.entities[id]

export function fetchRequest(url: RequestInfo, options: RequestInit, callbackAction: string, apiPrefix?: string, isIgnoreError?: boolean): IFetchAction {
  return {
    type: FETCH_ACTION,
    url, options, callbackAction,
    apiPrefix, isIgnoreError
  }
}

export function* takingResponse(callbackAction: string) {
  return yield take(callbackAction)
}

interface ICurrentSessionAnswer {
  id: string
  desk_id: string
  opened_at: string //data time
}

export type TCurrentSessionAnswer = ICurrentSessionAnswer | null

export function* selectDesk() {
  const res = yield race([take(START_WORK_SESSION), take(END_WORK)])
  if (res[1]) {
    yield put(selectDeskAction(null))
    yield put(statusChangingAction(FIRST_SCREEN))
    return yield * descSelection()
  } else {
    const desk: Desk = yield select(getDesk)
    const workSessionId = yield call(regWorkSession, desk)
    return [desk, workSessionId]
  }
}

export function* descSelection(): any {
  let desk: Desk | undefined = yield select(getDesk)
  if (!desk) {
    yield take(START_WORK)
    yield put(statusChangingAction(DESK_SELECTION))
  }
  return yield call(selectDesk)
}

export function* regWorkSession(desk: Desk): any {
  let workseSessionID: EntityId
  const id = desk.id
  let payload: any
  try {
    payload = yield call(fetchM, 'work_sessions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ desk_id: id, notification_session: (window as any).pSubscription })
    })
  } catch (e) {
    yield put(selectDeskAction(null))
    yield put(statusChangingAction(DESK_SELECTION))
    const dw = yield call(selectDesk)
    return dw[1]
  }
  if (!payload.success) {
    // throw "Worksession open problem"
    //TODO:
    workseSessionID = "problem duiring create"
  } else {
    workseSessionID = payload.model.id
    yield put(setWorkSessionAction(workseSessionID))
  }
  return workseSessionID
}

export function* startWorkSession() {
  const existedSession: TCurrentSessionAnswer = yield call(fetchM, 'work_sessions/current', { method: "GET" })
  let workseSessionID: string
  let desk: Desk
  if (existedSession) {
    workseSessionID = existedSession.id
    yield put(setWorkSessionAction(workseSessionID))
    const actDeskLoad = loadDesk(existedSession.desk_id)
    yield put(actDeskLoad)
    yield take(actDeskLoad.callbackAction)
    desk = yield select(selectDesc, existedSession.desk_id)
    yield put(selectDeskAction(desk))
  } else {
    const deskWS = yield call(descSelection)
    desk = deskWS[0]
    workseSessionID = deskWS[1]
  }
  const actHubLoad = loadHub(desk.hub_id)
  const actLineLoad = loadLine(desk.line_id)
  yield put(actLineLoad)
  yield put(actHubLoad)
  yield put(statusChangingAction(WAIT_TICKET))
  return workseSessionID
}

export function* lookForwardTicket() {
  let payload: any

  do {
    payload = yield getCurrentTicket()
    if (!payload) {
      yield race([
        take(PUSH_ICOMING),
        // delay(5000),
      ])
    }
  } while (!payload)

  const ticket: Ticket = Ticket.buildFromRaw(payload, Ticket)
  ticket.history = yield* fetchM(`tickets/${ticket.id}/changes`, { method: 'GET' })
  return ticket
}

function* listenerHold() {
  while(true) {
    const push = yield take(PUSH_ICOMING)
    if (push.payload.event === "ticket_hold") {
      window.location.reload()
    }
  }
}

export function* handleQueue() {
  while (true) {
    const ticketIncoming: Ticket = yield call(lookForwardTicket)
    yield put(setCurrentTicketAction(ticketIncoming))

    if (ticketIncoming.state === "assigned") {
      yield put(statusChangingAction(WAIT_CLIENT))
      let res: { incoming: any, noClient: any, recall: any }
      do {
        res = yield race({
          incoming: take(CLIENT_INCOMING),
          noClient: take(MISS_CLIENT),
          recall: take(RECALL)
        })
        if (res.recall) {
          yield call(recall, ticketIncoming)
        }
      } while (res.recall)
      const { incoming, noClient } = res
      if (incoming) {
        yield* handleClient(ticketIncoming)
        yield put(statusChangingAction(WAIT_TICKET))
      }
      else {
        if (noClient) {
          yield* fetchM(`tickets/${ticketIncoming.id}/return`, { method: "PATCH" })
          yield put(statusChangingAction(WAIT_TICKET))
        }
      }
    } else {
      yield* handleClient(ticketIncoming)
      yield put(statusChangingAction(WAIT_TICKET))
    }
  }
}

function* recall(ticketIncoming: Ticket) {
  yield* fetchM(`tickets/${ticketIncoming.id}/recall`, { method: "PATCH" })
}

function* reassign(ticketIncoming: Ticket) {
  yield* fetchM(`tickets/${ticketIncoming.id}/reassign`, { method: "PATCH" })
}

function* currentTicketRecall(action: ICurrentTicketRecallAction) {
  yield call(reassign, action.payload)
}

export function* handleClient(ticketIncoming: Ticket) {
  if (ticketIncoming.state === "assigned") {
    yield startTicket(ticketIncoming.id)
  }
  ticketIncoming.history = yield* fetchM(`tickets/${ticketIncoming.id}/changes`, { method: 'GET' })
  yield put(statusChangingAction(CLIENT_HENDLING))
  let raceResult = yield race({
    finish: take(FINISH_TICKET),
    forwarding: take(SELECT_ANOTHER_LINE),
    finishWithEnd: take(FINISH_AND_STOP_TICKET),
    cancel: take(CANCEL_TICKET_ACTION)
  })

  if (!raceResult.cancel) {
    const errorsClientData = yield validateClientData(yield select(getClientData))
    const errorsExpertData = yield validateExpertData(ticketIncoming, yield select(getExpertData))

    if (Object.keys(errorsClientData).map(key => errorsClientData[key]).includes(true)
      || errorsExpertData.map((value: any) => value.error).includes(true)) {
      yield put(validationFieldsAction("expert", errorsClientData, errorsExpertData))
      yield put(warningMessageAction(i18next.t('error required fields')))
      return
    }
  }

  if (!raceResult.cancel) {
    yield* updateData(ticketIncoming)
  } if (raceResult.finishWithEnd) {
    yield* fetchM(`tickets/${ticketIncoming.id}/finish`, { method: "PATCH" })
    yield put(statusChangingAction(WAIT_WORK_SESSION))
    yield put(stopWorkSessionAction())
  } else {
    if (raceResult.finish) {
      yield* fetchM(`tickets/${ticketIncoming.id}/finish`, { method: "PATCH" })
    } else if (raceResult.forwarding) {
      raceResult.forwarding.ticketId = ticketIncoming.id
      yield forwardingTicket(raceResult.forwarding)
    } else if (raceResult.cancel) {
      yield* fetchM(`tickets/${ticketIncoming.id}/cancel`, { method: "PATCH" })
    }
  }

  yield put(clearValidationFieldsAction())
}

function* forwardingTicket({ lineId, ticketId }: ISelectAnotherLine) {
  const options = {
    method: "PATCH",
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ line_id: lineId })
  }
  yield call(fetchM, `tickets/${ticketId}/forward`, options)
}

function* updateData(ticketIncoming: Ticket) {
  const expertData: TExpertData = yield select(getExpertData)
  const clientData: TClientData = yield select(getClientData)
  const options = {
    method: "PATCH",
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ expert_data: expertData, client_data: clientData })
  }
  yield call(fetchM, `tickets/${ticketIncoming.id}`, options)
}

export function* deskSelection() {
  yield put(statusChangingAction(WAIT_WORK_SESSION))
}

export function* changeDesk() {
  yield put(statusChangingAction(DESK_SELECTION))
}

function* helpRequest() {
  const worksessionId = yield select((st: AppState) => st.expertPage.workSessionId)
  yield put(fetchRequest(`work_sessions/${worksessionId}/help_request`,
    { method: "POST" }, "NOTHING"))
}

export const SELECT_TICKET_ACTION = "SELECT_TICKET_ACTION"

interface ISelectTicketAction{
  type: typeof SELECT_TICKET_ACTION
  ticketId: string
}

export function selectTicketAction(ticketId: string): ISelectTicketAction{
  return {
    type: SELECT_TICKET_ACTION,
    ticketId: ticketId
  }
}

function* selectTicket(action: ISelectTicketAction) {
  yield put(fetchRequest(`tickets/${action.ticketId}/assign`, {
    method: "PATCH"
  }, ''))
}

export const GET_MANUAL_LINE_TICKETS = "GET_MANUAL_LINE_TICKETS"

interface IGetManualLineTicketsAction{
  type: typeof GET_MANUAL_LINE_TICKETS
  lineId: string
  status: string
  view: string
}

export function getManualLineTicketsAction(lineId: string, status: string, view: string): IGetManualLineTicketsAction{
  return {
    type: GET_MANUAL_LINE_TICKETS,
    lineId: lineId,
    status: status,
    view: view
  }
}

function* getManualLineTickets({lineId, status, view}: IGetManualLineTicketsAction) {
  yield fromServerManualLineTickets(lineId, status, view)
  while (true) {
    const action = yield take(PUSH_ICOMING)
    if (action.payload.event == "ticket_registered" || action.payload.event == "ticket_assigned")
      yield fromServerManualLineTickets(lineId, status, view)
  }
}

function* fromServerManualLineTickets(lineId: string, status: string, view: string) {
  const {RELOAD_ACTION_TYPE} = buildActionTypeName(Ticket)
  const tickets = yield getTicketsForLine(lineId, status, view)
  yield put({
    type: RELOAD_ACTION_TYPE,
    response: tickets
  })
}

export const GET_LINES_FOR_FORWARD = "GET_LINES_FOR_FORWARD"

interface IGetLinesForForwardAction{
  type: typeof GET_LINES_FOR_FORWARD
  ticketId: EntityId
}

export function getLinesForForwardAction(ticketId: EntityId): IGetLinesForForwardAction{
  return {
    type: GET_LINES_FOR_FORWARD,
    ticketId: ticketId
  }
}

function* getLinesForForwardAnotherLine({ticketId}: IGetLinesForForwardAction) {
  const lines = yield getLinesForForward(ticketId)
  const result = lines.map((line: any) => {return Line.buildFromRaw(line, Line)})
  yield put({
    type: SUCCESS_ACCESS_FORWARD_LINES,
    payload: result
  })
}

export function* expertPageBp() {
  yield takeEvery(CURRENT_TICKET_RECALL, currentTicketRecall)
  yield takeEvery(DESK_SELECT_ACTION, deskSelection)
  yield takeEvery(DESK_CHANGE_ACTION, changeDesk)
  yield takeEvery(HELP_REQUEST, helpRequest)
  yield takeEvery(TICKETS_HISTORY_LOAD, loadHistory)
  yield takeEvery(SELECT_TICKET_ACTION, selectTicket)
  yield takeEvery(GET_MANUAL_LINE_TICKETS, getManualLineTickets)
  yield takeEvery(SELECT_ANOTHER_LINE_MANUAL_LINE, forwardingTicket)
  yield takeEvery(GET_LINES_FOR_FORWARD, getLinesForForwardAnotherLine)
  yield fork(listenerHold)
  while (true) {
    const workSessionId = yield call(startWorkSession)
    try {
      yield race([call(handleQueue), take(STOP_WORK_SESSION)])
      yield call(fetchM, `work_sessions/${workSessionId}/close`, { method: "PATCH" })
    } catch (e) {
      yield put(statusChangingAction(FIRST_SCREEN))
      yield put(successMessageAction(i18next.t('ticket was changed')))
      console.log("saga expert page reload reason: ", e)
    }
    yield put(statusChangingAction(WAIT_WORK_SESSION))
  }
}
