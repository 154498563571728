import React, { useState, useEffect } from "react";
import {
  List,
  makeStyles,
  Container,
  Paper,
  Box,
  TextField,
  Button,
  Typography,
  Grid,
} from "@material-ui/core";
import { MainMenu } from "../shared/main_menu";
import MiniDrawer from "../../../shared/components/mini_drawer";
import { Ticket } from "../../../models/ticket";
import ExpertDataEditor from "../../../models/ticket/components/expert_data";
import { buildRaw } from "../../../features/entity/helpers/buildRaw";
import { RemoteSyncLineContainer, Line } from "../../../models/line";
import { AppState } from "../../store";
import { useSelector, useDispatch } from "react-redux";
import { Hub, RemoteSyncHubContainer } from "../../../models/hub";
import Select from "../statistics/components/select";
import { useTranslation } from "react-i18next";
import { createTicketAction } from "./saga";
import { RemoteSyncTicTagContainer, TicTag } from "../../../models/tic_tag";
import { EntityId } from "../../../features/entity/type";
import {
  loadFilterFromLocalStorage,
  loadFilterToLocalStorage,
  NameFilter,
  TypeFilter,
} from "../statistics/pages/helper";

import PhoneTickets from "./phone_ticket";
import { City } from "../../../models/city";
import { clearValidationFieldsAction } from "../../../shared/validation/validation_store";
import { TicketLoyalty } from "../../../models/ticket/ticket";
import { User } from "../../../models/user";
import { TExpertData } from "../../../models/ticket/type";
import {
  RemoteSyncTicketTemplateContainer,
  TicketTemplate,
} from "../../../models/ticket_template";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
  },
  container: {
    paddingTop: theme.spacing(0),
    paddingBottom: 15,
    margin: "10px 0 0 0",
    paddingLeft: 0,
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  appBarSpacer: theme.mixins.toolbar,

  paper: {
    marginTop: theme.spacing(0),
    padding: 24,
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
}));

export default () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { hubs, lines, role, tags, cities, user, validation, ticketTemplates } =
    useSelector((state: AppState) => ({
      role: state.policy.currentRole,
      tags: [
        ...Object.keys(state.tags.entities).map(
          (k) => state.tags.entities[k] as TicTag
        ),
        { display_name: t("wihout tag"), id: "nothing" },
      ],
      hubs: Object.keys(state.hubs.entities).map(
        (k) => state.hubs.entities[k] as Hub
      ),
      lines: Object.keys(state.lines.entities).map(
        (k) => state.lines.entities[k] as Line
      ),
      cities: Object.keys(state.cities.entities).map(
        (k) => state.cities.entities[k] as City
      ),
      ticketTemplates: Object.keys(state.ticket_templates.entities).map(
        (k) => state.ticket_templates.entities[k] as TicketTemplate
      ),
      user: Object.keys(state.users.entities)
        .map((k) => state.users.entities[k] as User)
        .find((user) => user.id == state.authentication.current_user_id),
      validation:
        state.validation.page === "new_ticket" ? state.validation : null,
    }));

  const [expertData, setExpertData] = useState<TExpertData>({});

  const lineIdLS = loadFilterFromLocalStorage(
    TypeFilter.Ticket,
    NameFilter.Line
  );
  const hubIdLS = loadFilterFromLocalStorage(TypeFilter.Ticket, NameFilter.Hub);
  const tagIdLS = loadFilterFromLocalStorage(TypeFilter.Ticket, NameFilter.Tag);
  const loyaltyIdLS = loadFilterFromLocalStorage(
    TypeFilter.Ticket,
    NameFilter.Loyality
  );

  const [hubId, setHubId] = useState<string>(hubIdLS);
  const [lineId, setLineId] = useState<string>(lineIdLS);
  const [tagId, setTagId] = useState<EntityId>(tagIdLS || "nothing");
  const [loyalty, setLoyalty] = useState<string>(loyaltyIdLS || "normal");

  const [city, setCity] = useState<City>();

  const [message, setMessage] = useState("");

  const [ticket, setTicket] = useState<Ticket>(
    Ticket.buildFromRaw(
      {
        client_data: {
          phone: "",
          first_name: "",
          last_name: "",
        },
      },
      Ticket
    )
  );

  const updateTicket = () =>
    setTicket(Ticket.buildFromRaw(buildRaw(ticket, Ticket), Ticket));
  const clientDataChangeFactory =
    (key: string) =>
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      ticket.client_data[key] = event.target.value;
      updateTicket();
    };

  const clientDataPhoneChangeFactory = (key: string) => (value: string) => {
    ticket.client_data[key] = value;
    updateTicket();
  };

  const setCurrentCity = () => {
    if (hubs.length > 0 && cities.length > 0) {
      const currentHubId = role === "admin" ? hubId : user?.hub_id;
      let hub = hubs.find((h) => h.id === currentHubId);
      if (!hub) hub = hubs[0];

      const city = cities.find((c) => c.id === hub?.city_id);
      setCity(city);
    }
  };

  if (!city && hubId && cities.length > 0) {
    setCurrentCity();
  }

  const send = () => {
    dispatch(
      createTicketAction(
        {
          ...buildRaw({ ...ticket, expert_data: expertData }, Ticket),
          hub_id: hubId,
          line_id: lineId,
          priority: loyalty,
          tag_id:
            tagId == "nothing" || tagId == "" || tagId == "-1" ? null : tagId,
        },
        message
      )
    );
  };

  useEffect(() => {
    if (!city && hubs.length > 0) {
      setHubId(hubs[0].id);
    }
  }, [hubs.length]);

  useEffect(() => {
    loadFilterToLocalStorage(TypeFilter.Ticket, NameFilter.Line, lineId);
  }, [lineId]);

  useEffect(() => {
    loadFilterToLocalStorage(TypeFilter.Ticket, NameFilter.Hub, hubId);
  }, [hubId]);

  useEffect(() => {
    loadFilterToLocalStorage(TypeFilter.Ticket, NameFilter.Tag, tagId);
  }, [tagId]);

  useEffect(() => {
    loadFilterToLocalStorage(TypeFilter.Ticket, NameFilter.Loyality, loyalty);
  }, [loyalty]);

  useEffect(() => {
    setCurrentCity();
  }, [hubId]);

  useEffect(() => {
    return () => {
      dispatch(clearValidationFieldsAction());
    };
  }, []);

  const classes = useStyles();
  const menu = (
    <List>
      <MainMenu />
    </List>
  );

  const validateClassName = "ep-failed-validate-field";

  return (
    <MiniDrawer headerTitle="Admin page" menu={menu}>
      <main className={classes.content + " info-window"}>
        <RemoteSyncLineContainer />
        <RemoteSyncHubContainer />
        <RemoteSyncTicTagContainer />
        <RemoteSyncTicketTemplateContainer />
        <Box className="title-page-margin">
          <Typography variant="h5" className="title-page">
            {t("create ticket")}
          </Typography>
        </Box>
        <Container maxWidth="lg" className={classes.container}>
          <Paper className={classes.paper}>
            <Box>
              <Typography style={{ fontSize: "18px", fontWeight: 420 }}>
                {t("input data in ticket")}
              </Typography>
            </Box>
            <Grid container direction="row" justify="space-between">
              <Box>
                <Box marginLeft="-5px">
                  {Object.keys(ticket?.client_data || {}).map((prop, index) =>
                    prop !== "phone" ? (
                      <TextField
                        key={index}
                        label={t(prop)}
                        qa-tag={"ticket-name"}
                        value={ticket?.client_data[prop]}
                        onChange={clientDataChangeFactory(prop)}
                        className={`ep-client-data cl-field ${
                          validation?.errorsClientData[prop] &&
                          !ticket?.client_data[prop]
                            ? validateClassName
                            : ""
                        }`}
                      />
                    ) : (
                      <PhoneTickets
                        value={ticket?.client_data[prop]}
                        phone_code={city?.phone_code || ""}
                        className={
                          validation?.errorsClientData[prop] &&
                          ticket?.client_data[prop]?.trim()?.length < 12
                            ? validateClassName
                            : "" + "qa-tag-phone"
                        }
                        key={index}
                        label={t(prop)}
                        onChange={clientDataPhoneChangeFactory(prop)}
                        minPhoneNumber={2}
                      />
                    )
                  )}
                </Box>
                <Box>
                  <Box
                    marginLeft="-5px"
                    marginTop="30px"
                    className="ticket-create-select-wrapper"
                  >
                    <Select
                      collection={lines}
                      val={lineId}
                      setVal={setLineId}
                      label={t("line")}
                    />
                  </Box>
                  <Box
                    marginLeft="-5px"
                    className="ticket-create-select-wrapper"
                  >
                    <Select
                      collection={["high", "elevated", "normal"].map((v) => ({
                        id: v,
                        display_name: t(v),
                      }))}
                      val={loyalty}
                      setVal={(v) => setLoyalty(v as TicketLoyalty)}
                      label={t("loyalty level")}
                    />
                  </Box>
                  <Box
                    marginLeft="-5px"
                    className="ticket-create-select-wrapper"
                  >
                    <Select
                      collection={tags}
                      val={tagId}
                      setVal={setTagId}
                      label={t("tag")}
                    />
                  </Box>
                  {role === "admin" ? (
                    <Box
                      marginLeft="-5px"
                      className="ticket-create-select-wrapper"
                    >
                      <Select
                        collection={hubs}
                        val={hubId}
                        setVal={setHubId}
                        label={t("hub")}
                      />
                    </Box>
                  ) : null}
                </Box>
                <Box display="flex" flexDirection="row">
                  {(role === "admin" || role === "manager") &&
                  ticketTemplates &&
                  ticketTemplates.length > 0 ? (
                    <Box
                      display="flex"
                      flexDirection="row"
                      flexWrap="wrap"
                      style={{ marginLeft: "-5px" }}
                    >
                      <ExpertDataEditor
                        ticket={
                          {
                            ticket_template: {
                              fields: ticketTemplates.filter(
                                (s) => s.enabled
                              )[0].fields,
                              display_name: ticketTemplates.filter(
                                (s) => s.enabled
                              )[0].display_name,
                              enabled: ticketTemplates.filter(
                                (s) => s.enabled
                              )[0].enabled,
                              toNoField: ticketTemplates.filter(
                                (s) => s.enabled
                              )[0].toNoField,
                              id: ticketTemplates.filter((s) => s.enabled)[0]
                                .id,
                            },
                          } as Ticket
                        }
                        expertData={expertData}
                        setExpertData={setExpertData}
                        lang={t("current lang")}
                        validation={validation}
                      />
                    </Box>
                  ) : null}
                </Box>
              </Box>

              <Box>
                <Box>
                  <TextField
                    label={t("comment")}
                    value={message}
                    onChange={(e) => setMessage(e.currentTarget.value)}
                    rows={6}
                    qa-tag="comment"
                    multiline
                    className={"ep-client-data cl-field area-field"}
                  />
                </Box>
              </Box>
            </Grid>

            <Box flexGrow={1} />
            <Box display="flex" flexDirection="row">
              <Box flexGrow={1} />
              <Button
                variant="contained"
                color="primary"
                qa-tag="create-new-ticket"
                className="primary-button"
                onClick={send}
                disableRipple
              >
                {t("create")}
              </Button>
            </Box>
          </Paper>
        </Container>
      </main>
    </MiniDrawer>
  );
};
