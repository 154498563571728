export default {
    translations: {

        "current lang":
            "am",

        "too_many_pre_entries_per_day":
            "Նույն օրվա մեջ գրանցումների չափազանց մեծ թիվ",

        "Repeated input of phone!":
            "Հեռախոսահամարի կրկնակի մուտքագրում!",

        "webpush_problem":
            "Ձեր դիտարկիչում չեն աշխատում web push ծանուցումները։ Դա կարող է հանգեցնել ոչ ճշգրիտ աշխատանքի.",

        "Input other number":
            "Մուտքագրել այլ հեռախոսահամար",

        "Cancel":
            "Չեղարկում",

        "Start":
            "Սկսել աշխատանքը",

        "Start queue handling, when you will be ready":
            "Երբ պատրաստ լինեք, սկսեք հերթի սպասարկումը",

        "Start queue handling":
            "Սկսել սպասարկումը",

        "Alarm button":
            "Տագնապի կոճակ",

        "Change desk":
            "Փոխել պատուհանը",

        "Please, choose your table":
            "Խնդրում եմ, ընտրեք պատուհանը",

        "Before the handling":
            "Սպասարկումը սկսելուց առաջ",

        "Expert work desk":
            "Փորձագետի աշխատանքային սեղան",

        "Next":
            "Շարունակել",

        "End work":
            "Ավարտել աշխատանքը",

        "Wait for client assignment":
            "Հաճախորդի նշանակում",

        "Client incoming":
            "Հաճախորդը մոտեցել է",

        "Wait for client":
            "Սպասում ենք հաճախորդին",

        "No client":
            "Հաճախորդը չկա",

        "Recall":
            "Նորից հրավիրել",

        "Next client":
            "Հաջորդը",

        "Finish and interupt":
            "Ավարտել և ընդհատել աշխատանքը",

        "Forward to other queue":
            "Փոխանցել",

        "Return to queue":
            "Վերադարձնել հերթ",

        "Freeze client":
            "Սառեցնել հայտը",

        "Session data":
            "Սեսիայի տվյալները",

        "visit_history":
            "Այցելությունների պատմություն",

        "created_by_terminal":
            "Ստեղծված է տերմինալում",

        "enqueued":
            "Հերթում է",

        "started":
            "Սպասարկում",

        "assigned":
            "Նշանակված է",

        "finished":
            "Ավարտված է",

        "cancelled":
            "Չեղարկված է",

        "forwarded":
            "Փոխանցված է",

        "holded":
            "Սառեցված է",

        "recalled":
            "Կրկին հրավիրված է",

        "returned":
            "Վերադարձված է",

        "checked_in":
            "Ստուգված է",

        "Please, select the line":
            "Ընտրեք հերթը",

        "search":
            "Փնտրել",

        "display_name":
            "Անվանում",

        "lang":
            "Լեզու",

        "phone_code":
            "Երկրի հեռախոսային կոդը",

        "add":
            "Ավելացնել",

        "assignment":
            "Նշանակել",

        "configurate":
            "Փոփոխել",

        "time_slots":
            "Աշխատանքի տևողությունը",

        "devices":
            "Սարքավորումներ",

        "address":
            "Հասցե",

        "city_id":
            "Քաղաք",

        "role_id":
            "Դեր",

        "name":
            "Օգտատեր",

        "from":
            "սկսած",

        "till":
            "մինչև",

        "hour_short":
            "ժ",

        "min_short":
            "ր",

        "human_short":
            "հոգի",

        "Period":
            "Ժամանակահատված",

        "Capacity":
            "Հաճախորդների թիվը",

        "Time slots":
            "Վարորդների կենտրոնի աշխատանքի տևողություն",

        "kind":
            "Տեսակ",

        "state":
            "Կարգավիճակ",

        "activation_code":
            "Ակտիվացման կոդ",

        "Fields":
            "Դաշտեր",

        "default":
            "Ենթադրաբար",

        "enabled":
            "Ակտիվացված է",

        "Admin":
            "Ադմինիստրատոր",

        "electric_queue":
            "Էլեկտրոնային հերթ",

        "ticket":
            "Հայտ",

        "desk":
            "Պատուհան",

        "waiting":
            "Սպասում",

        "expert":
            "Փորձագետ",

        "Expert":
            "Փորձագետ",

        "city":
            "Քաղաք",

        "hub":
            "Վարորդների կենտրոն",

        "experts":
            "Փորձագետներ",

        "to":
            "մինչև",

        "Tickets by hubs":
            "Այցելությունների պատմություն ըստ վարորդների կենտրոնների",

        "Tickets by experts":
            "Այցելությունների պատմություն ըստ փորձագետների",

        "user_id":
            "Օգտատեր",

        "hub_id":
            "Վարորդների կենտրոն",

        "total":
            "Ընդհանուր",

        "line_id":
            "Հերթ",

        "avatar":
            "Պրոֆիլի նկար",

        "email":
            "էլ․ փոստ",

        "password":
            "Ծածկագիր",

        "ticket_no":
            "Հայտ",

        "line":
            "Հերթ",

        "Work session by experts":
            "Աշխատաժամանակի վերահսկում ըստ փորձագետների",

        "Work session by hubs":
            "Աշխատաժամանակի վերահսկում ըստ վարորդների կենտրոնների",

        "date":
            "Ամսաթիվ",

        "closed_at":
            "Ավարտ",

        "Expert load":
            "Փորձագետների բեռնում",

        "interaction_time":
            "Սպասարկում",

        "block/reset":
            "Բլոկավորում/Զրոյացում",

        "created_at":
            "Ստեղծված է",

        "started_at":
            "Սկիզբ",

        "finished_at":
            "Ավարտված է",

        "wait_time":
            "Սպասում",

        "phone":
            "Հեռախոսահամար",

        "tickets":
            "Հայտեր",

        "dashboard":
            "Դաշբորդ",

        "tickets done":
            "Սպասարկված այցելուների թիվը",

        "waiting time":
            "Հերթում սպասելու ժամանակը",

        "ticket topic":
            "Դիմումի թեմատիկա",

        "clients":
            "Այցելուներ",

        "expert work":
            "Փորձագետների աշխատանքը",

        "work_time":
            "Աշխատանքի տևողությունը",

        "working":
            "Սպասարկում",

        "created":
            "Ստեղծված է",

        "tickets in work":
            "Սպասարկվող այցելուների թիվը",

        "tickets wait":
            "Սպասող այցելուների թիվը",

        "manager in work":
            "Զբաղված փորձագետների թիվը",

        "today":
            "Այսօր",

        "yesterday":
            "Երեկ",

        "week":
            "Շաբաթվա մեջ",

        "month":
            "Ամսվա մեջ",

        "client":
            "Այցելու",

        "save":
            "Պահպանել",

        "cancel ticket":
            "Չեղարկել հայտը",

        "help request":
            "օգնության կարիք ունի",

        "need_help_on_desk":
            "Օգնության կարիք կա",

        "Manager":
            "Կառավարիչ",

        "back":
            "Ետ",

        "towns":
            "Քաղաքներ",

        "lines":
            "Հերթեր",

        "desks":
            "Պատուհաններ",

        "hubs":
            "Վարորդների կենտրոններ",

        "users":
            "Օգտատերեր",

        "templates":
            "Կաղապարներ",

        "ticket was changed":
            "Հայտը փոփոխվել է",

        "high":
            "Ամենաբարձր",

        "elevated":
            "Բարձր",

        "normal":
            "Նորմալ",

        "loyalty level":
            "Լոյալության մակարդակ",

        "create ticket":
            "Հայտի ստեղծում",

        "create":
            "Ստեղծել",

        "tags":
            "Թեգեր",

        "tag":
            "Թեգ",

        "service":
            "Ծառայություն",

        "statistics":
            "Վիճակագրություն",

        "new ticket":
            "Ավելացնել հայտ",

        "admin":
            "Կարգավորումներ",

        "tv_display_name":
            "Անունը TV-ի վրա",

        "logout":
            "Դուրս գալ",

        "settings":
            "Կարգավորումներ",

        "electronic queue enrollment":
            "Գրանցում էլեկտրոնային հերթում",

        "to get in line":
            "Հերթագրվել",

        "sign up at another time":
            "Այլ ժամանակի համար հերթագրվել",

        "type phone":
            "Մուտքագրեք հեռախոսահամարը",

        "type first name":
            "Մուտքագրեք անունը",

        "type second name":
            "Մուտքագրեք ազգանունը",

        "next":
            "Շարունակել",

        "hello!":
            "Բարև Ձեզ",

        "you have joined the queue":
            "Դուք հերթագրված եք։ Ձեր տոմսի համարն է",

        "take the ticket":
            "Վերցրեք տոմսը և մի փոքր սպասեք։ Մենք Ձեզ կհրավիրենք",

        "thank you!":
            "Շնորհակալություն",

        "you have successfully signed up for the driver center":
            "Դուք հաջողությամբ հերթագրվել եք վարորդների կենտրոնում",

        "date time":
            "Ամսաթիվ և ժամանակ",

        "signup":
            "Գրանցվել",

        "russian":
            "Ռուսերեն",

        "russian_eda":
            "Ռուսերեն(eda)",

        "english":
            "Անգլերեն",

        "armenian":
            "հայերեն",

        "Please, choose your language":
            "Ընտրեք լեզուն",

        "Detail":
            "Մանրամասները",

        "by hubs":
            "Ըստ վարորդների կենտրոնների",

        "visit history":
            "Այցելությունների պատմություն",

        "work time conotrol":
            "Աշխատաժամանակի վերահսկողություն",

        "by experts":
            "Ըստ փորձագետների",

        "expert report":
            "Փորձագետների բեռնում",

        "select all":
            "Ընտրել բոլորը",

        "select nothing":
            "Ջնջել",

        "wihout tag":
            "Առանց թեգի",

        "first_name":
            "Անուն",

        "last_name":
            "Ազգանուն",

        "created_at_date":
            "Ստեղծված է",

        "session":
            "Սեսիա",

        "service history":
            "Սպասարկման պատմություն",

        "data":
            "Տվյալներ",

        "driver":
            "Այցելու",

        "look":
            "Դիտել",

        "origin":
            "Սկիզբ",

        "end":
            "Վերջ",

        "select_date_and_time":
            "Ընտրեք ամսաթիվը և ժամանակը",

        "start period":
            "Ժամանակահատվածի սկիզբ",

        "end period":
            "Ժամանակահատվածի վերջ",

        "served":
            "Սպասարկված է",

        "did not come":
            "Չի մոտեցել",

        "total tickets":
            "Հայտերի ընդհանուր թիվը",

        "service time":
            "Սպասարկման տևողությունը",

        "max service time":
            "Սպասարկման առավելագույն տևողությունը",

        "average service time":
            "Սպասարկման միջին տևողությունը",

        "min service time":
            "Սպասարկման նվազագույն տևողությունը",

        "h":
            "ժ",

        "min":
            "ր",

        "sec":
            "վրկ",

        "work time":
            "Աշխատանքի տևողությունը",

        "name client":
            "Այցելու",

        "took to work":
            "Վերցրել է սպասարկման",

        "completed":
            "Ավարտել է",

        "history change":
            "Փոփոխությունների պատմություն",

        "changes saved successfully":
            "Փոփոխությունները հաջողությամբ պահպանված են",

        "reset date":
            "Զրոյացնել ամսաթիվը",

        "input data in ticket":
            "Մուտքագրեք տվյալները նոր կտրոն ստեղծելու համար",

        "load yet":
            "Ներբեռնել",

        "close session":
            "Փակել սեսիան",

        "success close session":
            "Սեսիան հաջողությամբ ավարտված է",

        "failed close session":
            "Սեսիան ավարտված չէ",

        "tickets created by experts":
            "Օգտատերերի համար ստեղծված հայտեր",

        "error required fields":
            "Պարտադիր դաշտերը դատարկ են, կամ ոչ ճշգրիտ են լրացված",

        "by users":
            "Ըստ օգտատերերի",

        "select ticket":
            "Ընտրել հայտ",

        "select":
            "Ընտրել",

        "mode":
            "Ռեժիմ",

        "link":
            "Հղում",

        "waiting time per day":
            "Սպասելաժամանակը՝ օրվա կտրվածքով",

        "ticket processing time per day":
            "Հայտերի սպասարկման ժամանակը՝ օրվա կտրվածքով",

        "total working hours per day":
            "Աշխատանքի ընդհանուր տևողությունն օրվա կտրվածքով",

        "auto":
            "Ավտոմատ հերթ",

        "manual":
            "Ձեռքով կառավարվող հերթ",

        "cancelled_by_system":
            "Չեղարկված է համակարգի կողմից",

        "need help":
            "Օգնության կարիք կա!",

        "apply":
            "Ընդունել",

        "comment":
            "Մեկնաբանություն",

        "comment created":
            "Մեկնաբանությունը ստեղծված է!",

        "comment edited":
            "Մեկնաբանությունը փոփոխված է!",

        "comment deleted":
            "Մեկնաբանությունը ջնջված է!",

        "comments":
            "Մեկնաբանություններ",

        "change":
            "Փոփոխել",

        "delete":
            "Ջնջել",

        "sms":
            "SMS կարգավորում",

        "message":
            "Հաղորդագրություն",

        "sender":
            "Ուղարկող",

        "delay in minutes":
            "Ուշացում (րոպե)",

        "registered":
            "Գրանցված է",

        "enter time in minutes":
            "Մուտքագրեք ժամանակը րոպեներով",

        "terminal":
            "Տերմինալ",

        "select line":
            "Ընտրեք հերթը",

        "time wait in line":
            "Հերթում սպասելու ժամանակը",

        "min time wait":
            "Սպասելու նվազագույն ժամանակը",

        "avg time wait":
            "Սպասելու միջին ժամանակը",

        "max time wait":
            "Սպասելու առավելագույն ժամանակը",

        "time process ticket in line":
            "Հերթում հայտի մշակման տևողությունը",

        "min time process":
            "Մշակման մինիմալ տևողությունը",

        "avg time process":
            "Մշակման միջին տևողությունը",

        "max time process":
            "Մշակման առավելագույն տևողությունը",

        "action":
            "Գործողություն",

        "print":
            "Տպել",

        "hello":
            "Բարև Ձեզ",

        "you signed up":
            "Դուք հերթագրվել եք",

        "your ticket number":
            "Ձեր համարը հերթում:",

        "take the coupon and wait for you to be called":
            "Վերցրեք կտրոնը և սպասեք մինչև ձեզ կհրավիրեն.",

        "city_with_nubs_destroy":
            "Հնարավոր չէ ջնջել քաղաքը, եթե այն կցված է վարորդների կենտրոնին",

        "hub_with_ticket_destroy":
            "Հնարավոր չէ ջնջել վարորդների կենտրոնը, եթե այն ունի անավարտ հայտեր",

        "hub_with_work_sessions_destroy":
            "Հնարավոր չէ ջնջել վարորդների կենտրոնը, եթե այն ունի անավարտ սեսիաներ",

        "line_with_tickets_destroy":
            "Հնարավոր չէ ջնջել հերթը, եթե նրանում անավարտ հայտեր կան",

        "desk_with_ticket_destroy":
            "Հնարավոր չէ ջնջել պատուհանը, եթե նրանում սպասարկվող հայտ կա",

        "user_with_work_session_destroy":
            "Հնարավոր չէ ջնջել օգտատիրոջը, եթե նա ունի բաց սեսիաներ",

        "record_not_unique":
            "Յուրաքանչյուր գործողության արդյունքում դուք չեք կարող ավելացնել մեկից ավելի մեկնաբանություններ:",

        "oops an error occured":
            "Սխալ է տեղի ունեցել!",

        "link in sms":
            "Հղում sms- ներում",

        "tv":
            "Հեռուստատեսություն",

        "repeat call":
            "Կարող եք զանգը կրկնել ՝",

        "platinum_gold":
            "Պլատինե / Ոսկի",

        "silver_bronze":
            "Արծաթագույն / Բրոնզե",

        "without_status":
            "Առանց կարգավիճակի",

        "status_loyalty":
            "Ձեր հավատարմության կարգավիճակը",

        "invite":
            "Հրավիրում ենք"
    }
}
